<template>
  <div class="main login">
    <Loading v-if="isLoading"></Loading>
    <div class="main__block" v-if="customerList.length === 0">
      <div class="title">
        請輸入顧客資訊
      </div>
      <div class="flex f-col jcs aic">
        <input
          v-model="search.mobile"
          type="text"
          class="input__input"
          placeholder="請輸入顧客手機號碼"
        />
        <input
          v-model="search.name"
          type="text"
          class="input__input"
          placeholder="請輸入顧客姓名"
        />
        <input
          v-model="search.birthday"
          type="date"
          class="input__input"
          placeholder="請輸入顧客西元年生日"
        />
        <span class="muted" style="margin-bottom: 10px;">*手機號碼、姓名、生日請擇一輸入</span>
      </div>
      <div @click="searchCustomer" class="btn">確認</div>
    </div>
    <div class="main__block" v-else-if="customerList.length > 0 && !isNNP">
      <div class="title">
        選擇顧客
      </div>
      <div class="flex f-col jcs aic">
        <div class="btn" v-for="customer in customerList" :key="customer.cussn || customer.sn" @click="checkCustomer(customer)">
          {{ customer.name }}<p style="font-size: 14px;">({{ hiddenMobile(customer.mobile) }})</p>
        </div>
        <!-- <select v-model="tempCustomer" class="select">
          <option v-for="customer in customerList" :key="customer.cussn || customer.sn" :value="customer">{{ customer.name }}</option>
        </select>
        <div @click="checkCustomer" class="btn">確認</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loading from '../../components/Loading.vue';
export default {
  name: 'AirLeeCustomerLogin',
  components: {
    Loading,
  },
  data() {
    return {
      isNNP: false,
      search: {
        mobile: '',
        name: '',
        birthday: '',
      },
      tempCustomer: {
        mobile: '',
        type: '',
        cussn: '',
        sn: '',
      },
      customerList: [],
      clinic: {},
      clinicList: {},
    };
  },
  methods: {
    hiddenMobile(mobile) {
      // 隱藏手機號碼 0909559394 => 090***9394
      return mobile.replace(/(\d{3})\d{3}(\d{4})/, '$1***$2');
    },
    async getSettings() {
      try {
        const clinicCode = this.$route.query.clinicCode;
        await this.$store.dispatch('authModule/getSettings', clinicCode);
        this.clinic = JSON.parse(localStorage.getItem(`clinic_${clinicCode}`));
      } catch (e) {
        alert(e);
        console.log(e);
      }
    },
    async checkClinic() {
      try {
        if (this.$route.query.clinicCode) {
          localStorage.setItem('clinicCode', this.$route.query.clinicCode);
        } else {
          throw new Error('診所代碼錯誤,請重新登入！');
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    formValidate() {
      return new Promise((resolve, reject) => {
        const error = new Error();
        if(!this.search.mobile.trim() && !this.search.name.trim() && !this.search.birthday.trim()) {
          error.message = '手機號碼、姓名、生日請擇一輸入';
          reject(error);
        }
        resolve();
      })
    },
    async searchCustomer() {
      try {
        await this.formValidate();
        let data = {
          mobile: this.search.mobile,
          name: this.search.name,
          birthday: this.search.birthday,
        }
        // 若欄位無對應值，則不傳送該欄位
        for (let key in data) {
          if (!data[key]) {
            delete data[key];
          }
        }
        const clinicCode = this.$route.query.clinicCode;
        const { customers, newpatients, nnp } = await this.$store.dispatch('airleeResvModule/searchCustomer', { data, clinicCode: clinicCode });
        console.log(customers, newpatients, nnp);
        if (customers.length === 0 && newpatients.length === 0 && nnp) {
          this.isNNP = true;
          // 查無此顧客資料 視為「全新患者」,sn=0
          // 若無此顧客資料，提醒使用者輸入手機號碼
          if (!nnp.mobile) {
            throw new Error('查無此顧客資料，請輸入手機號碼以建立資料');
          }
          await this.checkCustomer({
            type: 'newpatient',
            name: '',
            sn: 0,
            mobile: nnp.mobile,
          });
        } else {
          this.isNNP = false;
          if (customers.length > 0) {
            customers.forEach(customer => {
              this.customerList.push({
                type: 'customer',
                name: customer.cusname,
                cussn: customer.cussn,
                mobile: customer.cusmob,
              });
            });
          }
          if (newpatients.length > 0) {
            newpatients.forEach(newpatient => {
              this.customerList.push({
                type: 'newpatient',
                name: newpatient.name,
                sn: newpatient.sn,
                mobile: newpatient.mobile,
              });
            });
          }
        }
        
      } catch (e) {
        console.log(e);
        alert(e.message);
      }
    },
    async checkCustomer(customer) {
      try {
        this.tempCustomer = customer;
        // 是否不開放新患者預約	
        let data = {};
        if (this.tempCustomer.cussn) {
          data = {
            mobile: this.tempCustomer.mobile,
            type: 'customer',
            cussn: this.tempCustomer.cussn,
          }
        } else {
          data = {
            mobile: this.tempCustomer.mobile,
            type: 'newpatient',
            sn: this.tempCustomer.sn,
          }
        }
        const clinicCode = this.$route.query.clinicCode;
        if (this.online_resv_np == 0 && data.type === 'newpatient') {
          throw new Error('此診所不開放新患者預約，請重新搜尋');
        }
        if (this.online_resv_op == 0 && data.type === 'customer') {
          throw new Error('此診所不開放舊患者預約，請重新搜尋');
        }
        await this.$store.dispatch('airleeResvModule/checkCustomer', { data, clinicCode: clinicCode, });
        this.$router.push(`/airlee/choose-service?clinicCode=${clinicCode}`);
      } catch (e) {
        console.log(e);
        alert(e.message);
        this.customerList = [];
      }
    },
    async init() {
      try {
        // 驗證token
        await this.$store.dispatch('airleeAuthModule/tokenValidate');
        await this.checkClinic();
        await this.getSettings();
        // 檢查是否有診間可以選擇
        if (this.rooms.length === 0) {
          alert('無可用診間');
          this.$router.push(`/airlee/choose-clinic`);
          return;
        }
        console.log('init');
      } catch (e) {
        alert(e.message);
        this.$router.push(`/airlee/staff/login`);
      }
    },
  },
  computed: {
    ...mapGetters(['isLoading']),
    ...mapGetters('authModule', ['onlineResvNp', 'onlineResvOp', 'roomsByC']),
    rooms() {
      const clinicCode = this.$route.query.clinicCode;
      return this.roomsByC(clinicCode);
    },
    online_resv_np() {
      const clinicCode = this.$route.query.clinicCode;
      return this.onlineResvNp(clinicCode);
    },
    online_resv_op() {
      const clinicCode = this.$route.query.clinicCode;
      return this.onlineResvOp(clinicCode);
    },

  },
  mounted() {},
  async created() {
    try {
      await this.init();
    } catch (e) {
      alert(e);
    }
  },
};
</script>
<style lang="scss" scoped>
.btn {
  margin-bottom: 20px;
}
.statement {
  width: 100%;
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  border: solid 1px rgba(255, 255, 255, 0.4);
  background-color: rgba(255, 255, 255, 0.2);
  span {
    color: #fff !important;
    letter-spacing: 3px;
    a {
      color: #178fac;
    }
  }
}
</style>
